
// import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/new-base-select'

// 查询
export const formConfig = (content) => {
  return [
    {
      label: '业务经理',
      prop: 'managerId',
      attrs: {
        filterable: true,
        clearable: true,
        placeholder: '请选择',
        options: content.busiManagerOptions,
        selectedField: ['busiManagerId', 'busiManagerName']
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      tag: BaseSelect
    },
    {
      label: '报告期数',
      prop: 'periodNo',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      attrs: {
        filterable: true,
        clearable: true,
        placeholder: '请选择',
        options: content.periodNumOptions,
        selectedField: ['periodNo', 'periodNoName']
      },
      tag: BaseSelect
    }

  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务经理',
      prop: 'managerName',
      'show-overflow-tooltip': true,
      align: 'left'
      // formatter: row => {
      //   return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // }
    },
    {
      label: '报告期数',
      prop: 'periodNo',
      'show-overflow-tooltip': true,
      minWidth: '120px'
    },
    {
      label: '当前应完成数量',
      prop: 'shouldFinished',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '当前已完成数量',
      prop: 'finished',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '当前未完成数量',
      prop: 'unFinished',
      minWidth: '120px',
      'show-overflow-tooltip': true
      // align: 'left'

    },
    {
      label: '历史报告逾期总数',
      prop: 'expired',
      minWidth: '120px',
      'show-overflow-tooltip': true
    }
  ]
}
