// 贷后报告制作情况统计
<template>
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
    </base-form>
    <!-- 按钮 -->
    <div class="main-page-table__header flex-between insertContent">
      <div class="left">
        <i class="iconfont icontanhao"></i>
        <p>表格中的“当前”，指贷后报告截止日（月中、月末）前五个工作日内</p>
      </div>
      <div class="right">
        <base-button
          label="导出全部明细"
          icon="iconfont iconxiazai"
          @click="exportAll"
        ></base-button>
      </div>
    </div>
    <!-- 表格 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'loanRostReportCount'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </base-table>
  </div>
</template>

<script>
// import { downFile } from '@/utils/devUtils'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formConfig, columnsConfig } from './utils/config.js'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
// import { financingApplyApi } from '@/api/businessApi.js'
import { loanApi } from '@/api/businessApi.js'
import { downFile } from '@/utils/devUtils'
import { getManager, getRoles } from '@/api/sys/user'

export default {
  components: { BaseForm, BaseTable, BaseButton },
  props: {},
  data () {
    return {
      fold: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      },
      tableData: [],
      periodNumOptions: [], // 报告期数
      busiManagerOptions: [], // 业务经理
      isywjl: '', // 角色是否为业务经理 '0' 不是，'1'是
      currentManagerId: '' // 当前登录的业务经理Id，判断是否是业务经理并保存Id
    }
  },
  // 计算属性 类似于data概念
  computed: {
    formConfig () {
      return formConfig(this)
    },
    columns () {
      return columnsConfig(this)
    },
    api () {
      return loanApi
    }
  },
  // 监控data中的数据变化
  watch: {
    queryParas: {
      handler (val) {
        // 期数获取后，角色查询成功后
        if (val.periodNo && val.getRoleOK) {
          this.handleFilter()
          // 只调用一次
          this.$set(val, 'getRoleOK', false)
        }
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    // 查询角色信息
    getRole () {
      getRoles(this.$store.state.user.userInfo.userId).then(res => {
        if (res.data) {
          // 业务经理角色，业务经理条件默认选择用户自己
          if (res.data.some(item => item.roleCode === 'ywjl_code')) {
            this.$set(
              this.queryParas,
              'managerId',
              this.$store.state.user.userInfo.userId
            )
          }
          // 插入值表示接口是否已调用成功
          this.$set(this.queryParas, 'getRoleOK', true)
        }
      })
    },
    // 获取业务经理下拉
    getBusinessMangeer () {
      getManager({ roleCode: 'ywjl_code' }).then(res => {
        this.busiManagerOptions = res.data.map(item => {
          return { busiManagerId: item.userId, busiManagerName: item.nickName }
        })
        this.getRole()
      })
    },
    // 查询报告期数
    getPeriodNum () {
      this.api.periodNum().then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.periodNumOptions.push({ periodNo: item, periodNoName: item })
          })
          this.$set(
            this.queryParas,
            'periodNo',
            this.periodNumOptions[0].periodNo
          )
        }
      })
    },
    // 导出
    exportAll () {
      loanApi.loanRostReportExport().then(res => {
        downFile(res, '贷后报告.xlsx')
      })
    },

    handleFilter () {
      this.$nextTick(() => {
        this.queryParas.pageIndex = 1
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getBusinessMangeer()
    this.getPeriodNum()
    // this.getRole()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // 监听2
    // const unWatch = this.$watch('queryParas', (newVal, oldVal) => {
    //   if (newVal.periodNo && newVal.getRoleOK) {
    //     this.handleFilter()
    //   }
    //   unWatch() // 取消监听
    // })
  }
}
</script>
<style lang="scss" scoped>
.insertContent {
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p{
      margin: 0;
    }
    .icontanhao {
      font-size: 24px;
      color: #2862e7;
      margin-right: 10px;
    }
  }
}
</style>
